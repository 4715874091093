import { createRouter, createWebHashHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";

import GastosRouter from "@/components/RecepcionGastos";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    //component: () => import( /* webpackChunkName: "home" */ '@/components/Login/Login')
    component: () =>
      import(/* webpackChunkName: "home" */ "@/components/HelloWorld.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/components/Login/Login.vue"),
  },
  {
    path: "/registro",
    name: "Registro",
    component: () =>
      import(
        /* webpackChunkName: "registro" */ "@/components/Login/Registro.vue"
      ),
  },
  {
    path: "/login/:brand",
    name: "LoginBrand",
    component: () =>
      import(
        /* webpackChunkName: "loginbrand" */ "@/components/Login/Login.vue"
      ),
    props: true,
  },
  {
    path: "/hola",
    name: "HelloWorld",
    component: () =>
      import(/* webpackChunkName: "hola" */ "@/components/HelloWorld.vue"),
  },
  //
  {
    path: "/uxcliente",
    name: "UXCliente",
    component: () =>
      import(
        /* webpackChunkName: "uxcliente" */ "@/components/UXCliente/UXCliente.vue"
      ),
    children: [
      {
        path: "/",
        name: "UXClienteHome",
        redirect: "/uxcliente/anuncios",
      },
      {
        path: "entradassalidas",
        name: "UXClienteEntradasSalidasResidente",
        component: () =>
          import(
            /* webpackChunkName: "uxclienteentradassalidasres" */ "@/components/UXCliente/EntradasSalidasResidente.vue"
          ),
      },
      {
        path: "chat",
        name: "UXClienteChatGral",
        component: () =>
          import(
            /* webpackChunkName: "uxclientechatgral" */ "@/components/UXCliente/ChatInterface.vue"
          ),
      },
      {
        path: "chat/:idConversacion",
        name: "UXClienteChatConversacion",
        component: () =>
          import(
            /* webpackChunkName: "uxclientechatconversacion" */ "@/components/UXCliente/ChatInterface.vue"
          ),
        props: true,
      },
      {
        path: "anuncios",
        name: "UXClienteAnuncios",
        component: () =>
          import(
            /* webpackChunkName: "uxclienteanuncios" */ "@/components/UXCliente/Anuncios.vue"
          ),
      },
      {
        path: "documentos",
        name: "UXClienteDocumentos",
        component: () =>
          import(
            /* webpackChunkName: "uxclientedocumentos" */ "@/components/UXCliente/Documentos.vue"
          ),
      },
      {
        path: "pagos",
        name: "UXClientePagos",
        component: () =>
          import(
            /* webpackChunkName: "uxclientepagos" */ "@/components/UXCliente/Pagos.vue"
          ),
      },
      {
        path: "incidentes",
        name: "UXClienteIncidentes",
        component: () =>
          import(
            /* webpackChunkName: "uxclienteincidentes" */ "@/components/UXCliente/Incidentes.vue"
          ),
      },
      {
        path: "visitantes",
        name: "UXClienteVisitantesLista",
        component: () =>
          import(
            /* webpackChunkName: "uxclientevisitanteslista" */ "@/components/UXCliente/Visitantes.vue"
          ),
      },
      {
        path: "visitantes/:id",
        name: "UXClienteVisitantes",
        component: () =>
          import(
            /* webpackChunkName: "uxclientevisitanteid" */ "@/components/UXCliente/Visitante.vue"
          ),
        props: true,
      },
      {
        path: "es",
        name: "UXClienteEntradasSalidas",
        component: () =>
          import(
            /* webpackChunkName: "uxclienteentradassalidas" */ "@/components/UXCliente/EntradasSalidas2.vue"
          ),
      },
      {
        path: "reservar",
        name: "UXClienteReservar",
        component: () =>
          import(
            /* webpackChunkName: "uxclientereservar" */ "@/components/UXCliente/Reservar.vue"
          ),
      },
      {
        path: "micuenta",
        name: "UXClienteCuenta",
        component: () =>
          import(
            /* webpackChunkName: "uxclientecuenta" */ "@/components/UXCliente/Cuenta.vue"
          ),
      },
      {
        path: "documentacion",
        name: "UXClienteDocumentacion",
        component: () =>
          import(
            /* webpackChunkName: "uxclientedocumentacion" */ "@/components/UXCliente/Documentacion.vue"
          ),
      },
      {
        path: "estadodecuenta",
        name: "UXClienteEstadoDeCuenta",
        component: () =>
          import(
            /* webpackChunkName: "uxclienteestadodecuenta" */ "@/components/UXCliente/EstadoDeCuentaWrapper.vue"
          ),
      },
      {
        path: "tokenizartarjeta",
        name: "UXClienteTokenizarTarjeta",
        component: () =>
          import(
            /* webpackChunkName: "uxclientetokenizartarjeta" */ "@/components/UXCliente/TokenizarTarjeta.vue"
          ),
      },
      {
        path: "servicios",
        name: "UXClienteServicios",
        component: () =>
          import(
            /* webpackChunkName: "uxclienteservicios" */ "@/components/UXCliente/Servicios.vue"
          ),
      },
      {
        path: "vehiculos",
        name: "UXClienteVehiculos",
        component: () =>
          import(
            /* webpackChunkName: "uxclvehiculos" */ "@/components/UXCliente/Vehiculos.vue"
          ),
      },
      {
        path: "mascotas",
        name: "UXClMascotas",
        component: () =>
          import(
            /* webpackChunkName: "uxclmascotas" */ "@/components/UXCliente/Mascotas.vue"
          ),
      },
      {
        path: "tags",
        name: "UXClTags",
        component: () =>
          import(
            /* webpackChunkName: "uxcltags" */ "@/components/UXCliente/Tags.vue"
          ),
      },
      {
        path: "familia",
        name: "UXClFamilia",
        component: () =>
          import(
            /* webpackChunkName: "uxclfamilia" */ "@/components/UXCliente/Familia.vue"
          ),
      },
      {
        path: "directorio",
        name: "UXClDirectorio",
        component: () =>
          import(
            /* webpackChunkName: "uxcldirectorio" */ "@/components/UXCliente/Directorio.vue"
          ),
      },
      {
        path: "firmar/:id",
        name: "UXClFirmarDocumento",
        component: () =>
          import(
            /* webpackChunkName: "uxclfirmardocumento" */ "@/components/UXCliente/Firmar.vue"
          ),
        props: true,
      },
    ],
  },
  {
    path:'/proveedores',
    name:'ProveedoresMenu',
    component:()=>import('@/components/RecepcionGastos/Proveedores/Menu.vue'),
  },
  {
    path:'/proveedores/misdatos',
    name:'ProveedoresMisDatos',
    component:()=>import('@/components/RecepcionGastos/Proveedores/MisDatos.vue'),
  },
  {
    path:'/proveedores/misventas',
    name:'ProveedoresMisVentas',
    component:()=>import('@/components/RecepcionGastos/Proveedores/MisVentas.vue'),
  },
  {
    path:'/proveedores/subirfactura',
    name:'ProveedoresSubirFactura',
    component:()=>import('@/components/RecepcionGastos/Proveedores/SubirFactura.vue'),
  },
  {
    path: "/admin/matrizpagos",
    name: "AdminPagos",
    component: () =>
      import(
        /* webpackChunkName: "matrizpagos" */ "@/components/Admin/PagosManuales/MatrizPagos.vue"
      ),
  },
  {
    path: "/admin/saldonumero/:numero",
    name: "AdminSaldoNumero",
    component: () =>
      import(
        /* webpackChunkName: "saldonumero" */ "@/components/Admin/SaldoNumero.vue"
      ),
    props: true,
  },
  {
    path: "/admin/visitantes",
    name: "AdminVisitantes",
    component: () =>
      import(
        /* webpackChunkName: "pasevisitantelista" */ "@/components/UXCliente/Visitantes.vue"
      ),
  },
  {
    path: "/admin/entradassalidas",
    name: "AdminEntradasSalidas",
    component: () =>
      import(
        /* webpackChunkName: "adminentradassalidas" */ "@/components/Admin/EntradasSalidas.vue"
      ),
  },
  {
    path: "/pasevisitante/:id",
    name: "PaseDeVeisitante",
    component: () =>
      import(
        /* webpackChunkName: "pasevisitanteid" */ "@/components/UXCliente/Visitante.vue"
      ),
    props: true,
  },
  {
    path: "/eliminardatos",
    name: "eliminardatos",
    component: () =>
      import(
        /* webpackChunkName: "eliminardatos" */ "@/components/UXCliente/EliminarDatos.vue"
      ),
  },

  //
  {
    path: "/admin/admins",
    name: "AdministradoresLista",
    component: () =>
      import(
        /* webpackChunkName: "adminslista" */ "@/components/Admin/Admins/Usuarios.vue"
      ),
  },
  {
    path: "/admin/admins/:id",
    name: "AdministradorId",
    component: () =>
      import(
        /* webpackChunkName: "adminuserid" */ "@/components/Admin/Admins/Usuario.vue"
      ),
    props: true,
  },
  //
  {
    path: "/admin/amonestaciones",
    name: "Cotos",
    component: () =>
      import(
        /* webpackChunkName: "amonestacioneslista" */ "@/components/Admin/Amonestaciones/Amonestaciones.vue"
      ),
  },
  {
    path: "/admin/amonestaciones/:id",
    name: "Coto",
    component: () =>
      import(
        /* webpackChunkName: "amonestacionesver" */ "@/components/Admin/Amonestaciones/Amonestacion.vue"
      ),
    props: true,
  },
  {
    path: "/admin/avisos",
    component: () =>
      import(
        /* webpackChunkName: "adminavisoslista" */ "@/components/Admin/Avisos/Avisos.vue"
      ),
  },
  {
    path: "/admin/avisos/:id",
    component: () =>
      import(
        /* webpackChunkName: "adminavisoid" */ "@/components/Admin/Avisos/Aviso.vue"
      ),
    props: true,
  },
  {
    path: "/admin/anuncios",
    component: () =>
      import(
        /* webpackChunkName: "adminanunciosmesadirectiva" */ "@/components/Admin/Avisos/ListaMesaDirectiva.vue"
      ),
    props: true,
  },
  //
  {
    path: "/admin/bitacora",
    component: () =>
      import(
        /* webpackChunkName: "bitacora" */ "@/components/Admin/Bitacora/Bitacora.vue"
      ),
  },
  //
  {
    path: "/admin/cargos",
    component: () =>
      import(
        /* webpackChunkName: "admincargoslista" */ "@/components/Admin/CargosExtraordinarios/Cargos.vue"
      ),
  },
  {
    path: "/admin/cargos/:id",
    component: () =>
      import(
        /* webpackChunkName: "admincargover" */ "@/components/Admin/CargosExtraordinarios/Cargo.vue"
      ),
    props: true,
  },
  //
  {
    path: "/admin/cobranza",
    component: () =>
      import(
        /* webpackChunkName: "admincobranza" */ "@/components/Admin/CobranzaManual/Cobranza.vue"
      ),
  },
  //
  {
    path: "/admin/cotos",
    name: "CotosLista",
    component: () =>
      import(
        /* webpackChunkName: "cotoslista" */ "@/components/Admin/Cotos/Cotos.vue"
      ),
  },
  {
    path: "/admin/cotos/:id",
    name: "CotoVerId",
    component: () =>
      import(
        /* webpackChunkName: "cotoverid" */ "@/components/Admin/Cotos/Coto.vue"
      ),
    props: true,
  },
  //
  {
    path: "/admin/documentos",
    component: () =>
      import(
        /* webpackChunkName: "adminavisoslista" */ "@/components/Admin/Documentos/Documentos.vue"
      ),
  },
  {
    path: "/admin/documentos/:id",
    component: () =>
      import(
        /* webpackChunkName: "adminavisover" */ "@/components/Admin/Documentos/Documento.vue"
      ),
    props: true,
  },
  //
  {
    path: "/admin/empresa",
    name: "AdminEmpresa",
    component: () =>
      import(
        /* webpackChunkName: "adminempresa" */ "@/components/Admin/Empresa/Empresa.vue"
      ),
  },
  //
  {
    path: "/admin/gastos",
    component: () =>
      import(
        /* webpackChunkName: "gastoslista" */ "@/components/Admin/Gastos/Gastos.vue"
      ),
  },
  {
    path: "/admin/gastos/:id",
    component: () =>
      import(
        /* webpackChunkName: "gastoid" */ "@/components/Admin/Gastos/Gasto.vue"
      ),
    props: true,
  },
  //
  {
    path: "/admin/importar",
    component: () =>
      import(
        /* webpackChunkName: "gastosimportar" */ "@/components/Admin/ImportarUsuarios/Importar.vue"
      ),
  },
  //
  {
    path: "/admin/incidentes",
    component: () =>
      import(
        /* webpackChunkName: "adminincidenteslista" */ "@/components/Admin/Incidentes/Incidentes.vue"
      ),
  },
  {
    path: "/admin/incidente/:id",
    component: () =>
      import(
        /* webpackChunkName: "adminincidentever" */ "@/components/Admin/Incidentes/Incidente.vue"
      ),
    props: true,
  },
  //
  {
    path: "/admin/gastosmensuales",
    component: () =>
      import(
        /* webpackChunkName: "admingastosmensuales" */ "@/components/Admin/Gastos/Mensual.vue"
      ),
  },
  //
  {
    path: "/admin/rondines",
    component: () =>
      import(
        /* webpackChunkName: "adminrondineslista" */ "@/components/Admin/Incidentes/Rondines.vue"
      ),
  },
  {
    path: "/admin/rondines/reportes",
    component: () =>
      import(
        /* webpackChunkName: "adminrondinesreportes" */ "@/components/Admin/Incidentes/RondinReportes.vue"
      ),
  },
  {
    path: "/admin/rondines/:id",
    component: () =>
      import(
        /* webpackChunkName: "adminrondinesid" */ "@/components/Admin/Incidentes/Rondin.vue"
      ),
    props: true,
  },
  //
  {
    path: "/admin/pagosmanuales",
    component: () =>
      import(
        /* webpackChunkName: "adminpagosmanuales" */ "@/components/Admin/PagosManuales/PagosManuales.vue"
      ),
  },
  {
    path: "/admin/pagosmanuales/:id",
    component: () =>
      import(
        /* webpackChunkName: "adminpagomanualid" */ "@/components/Admin/PagosManuales/PagoManual.vue"
      ),
    props: true,
  },
  //
  {
    path: "/admin/proveedores",
    name: "Proveedores",
    component: () =>
      import(
        /* webpackChunkName: "adminproveedoreslista" */ "@/components/Admin/Proveedores/Proveedores.vue"
      ),
  },
  {
    path: "/admin/proveedores/:id",
    name: "Proveedor",
    component: () =>
      import(
        /* webpackChunkName: "adminproveedorid" */ "@/components/Admin/Proveedores/Proveedor.vue"
      ),
    props: true,
  },
  //
  {
    path: "/admin/recorridos",
    component: () =>
      import(
        /* webpackChunkName: "adminrecorridoslista" */ "@/components/Admin/Recorridos/Recorridos.vue"
      ),
  },
  {
    path: "/admin/recorridos/:id",
    component: () =>
      import(
        /* webpackChunkName: "adminrecorridoid" */ "@/components/Admin/Recorridos/Recorrido.vue"
      ),
    props: true,
  },
  //
  {
    path: "/admin/reportes",
    name: "Reportes",
    component: () =>
      import(
        /* webpackChunkName: "reporteshome" */ "@/components/Admin/Reportes/Reportes.vue"
      ),
  },
  {
    path: "/admin/reportes/pagos",
    name: "Pagos",
    component: () =>
      import(
        /* webpackChunkName: "reportespagos" */ "@/components/Admin/Reportes/Pagos.vue"
      ),
  },
  {
    path: "/admin/reportes/conekta",
    name: "Conekta",
    component: () =>
      import(
        /* webpackChunkName: "reportesconekta" */ "@/components/Admin/Reportes/Conekta.vue"
      ),
  },
  {
    path: "/admin/reportes/listaconekta",
    name: "Conekta",
    component: () =>
      import(
        /* webpackChunkName: "reportelistacone" */ "@/components/Admin/Reportes/ConektaLista.vue"
      ),
  },
  {
    path: "/admin/reportes/estadoresultados",
    name: "EstadoDeResultados",
    component: () =>
      import(
        /* webpackChunkName: "estadoderesultados" */ "@/components/Admin/Reportes/EstadoDeResultados.vue"
      ),
  },
  {
    path: "/admin/reportes/diario",
    name: "Diario",
    component: () =>
      import(
        /* webpackChunkName: "reportesdiario" */ "@/components/Admin/Reportes/Diario.vue"
      ),
  },
  {
    path: "/admin/reportes/simplificado",
    name: "Simplificado",
    component: () =>
      import(
        /* webpackChunkName: "reportessimplificado" */ "@/components/Admin/Reportes/ReporteSimplificado.vue"
      ),
  },
  {
    path: "/admin/reportes/multas",
    name: "Multas",
    component: () =>
      import(
        /* webpackChunkName: "reportesmultas" */ "@/components/Admin/Reportes/Multas.vue"
      ),
  },
  {
    path: "/admin/reportes/multas/:coto",
    name: "MultasIdCoto",
    component: () =>
      import(
        /* webpackChunkName: "reportesmultasidcoto" */ "@/components/Admin/Reportes/Multas.vue"
      ),
    props: true,
  },
  {
    path: "/admin/reportes/incidentes",
    name: "ReporteIncidntes",
    component: () =>
      import(
        /* webpackChunkName: "reporteincidentes" */ "@/components/Admin/Reportes/Incidentes.vue"
      ),
    props: true,
  },
  //
  {
    path: "/admin/servicios",
    component: () =>
      import(
        /* webpackChunkName: "adminservicioslista" */ "@/components/Admin/Servicios/Servicios.vue"
      ),
  },
  {
    path: "/admin/servicios/:id",
    component: () =>
      import(
        /* webpackChunkName: "adminservicioid" */ "@/components/Admin/Servicios/Servicio.vue"
      ),
    props: true,
  },
  //
  {
    path: "/admin/usuarios",
    name: "CondominosLista",
    component: () =>
      import(
        /* webpackChunkName: "usuarioslista" */ "@/components/Admin/Usuarios/Usuarios.vue"
      ),
  },
  {
    path: "/admin/usuarios/:id",
    name: "CondominosVer",
    component: () =>
      import(
        /* webpackChunkName: "usuarioid" */ "@/components/Admin/Usuarios/Usuario.vue"
      ),
    props: true,
  },
  //
  {
    path: "/uxvigilante",
    redirect: "/uxcliente/es",
  },
  {
    path: "/admin/vigilantes",
    name: "VigilantesLista",
    component: () =>
      import(
        /* webpackChunkName: "adminvigilanteslista" */ "@/components/Admin/Vigilantes/Vigilantes.vue"
      ),
  },
  {
    path: "/admin/vigilantes/:id",
    name: "VigilanteVerId",
    component: () =>
      import(
        /* webpackChunkName: "adminvigilanteverid" */ "@/components/Admin/Vigilantes/Vigilante.vue"
      ),
    props: true,
  },
  {
    path: "/admin/editoredocuenta",
    name: "EditorEstadoCUenta",
    component: () =>
      import(
        /* webpackChunkName: "editoredocuenta" */ "@/components/Admin/EditorEstadoCuenta/EditorLista.vue"
      ),
  },
  {
    path: "/admin/intereses",
    name: "InteresesHandler",
    component: () =>
      import(
        /* webpackChunkName: "intereseshandler" */ "@/components/Admin/Intereses/Intereses.vue"
      ),
  },
  {
    path: "/admin/notificaciones",
    name: "NotificacionesLista",
    component: () =>
      import(
        /* webpackChunkName: "notificacioneslista" */ "@/components/Admin/Notificaciones/Lista.vue"
      ),
  },
  {
    path: "/admin/notificaciones/:id",
    name: "NotificacionesHandlerId",
    component: () =>
      import(
        /* webpackChunkName: "notificacioneshandlerid" */ "@/components/Admin/Notificaciones/Notificacion.vue"
      ),
    props: true,
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes: [...(GastosRouter.router as RouteRecordRaw[]), ...routes],
});

export default router;
